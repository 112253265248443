.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.trapezoid {
    clip-path: polygon(15% 0, 85% 0, 100% 100%, 0% 100%);
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}