@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
}

body#text {
  margin: 0;
  font-weight: 400;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body, label, button.ui, h1, h2, h3, h4, h5, p {
  font-family: 'DM Sans', sans-serif;
  color: #464646;
}

h1 {
  letter-spacing: -3px;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3rem;
}

h1.jumbo {
  font-weight: 700;
  font-size: 4rem;
  line-height: 4rem;
}


h2 {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 500;
}

h3 {
  font-weight: bold;
  font-size:1.1rem;
}

h4 {
  font-weight: bold;
  font-size:1.1rem;
}

label {
  font-weight: bold;
  font-size:1.1rem;
}

.gradient {
  background: -webkit-linear-gradient(310deg, #a4e2d5, #08ae9c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.lightgradient {
  background: -webkit-linear-gradient(310deg, #F5F486, #DDB12D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
div.lightgradient {
  background: -webkit-linear-gradient(310deg, #F5F486, #DDB12D);
}

.blacktowhite {
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, #0E0E0E 50%, #0E0E0E 100%);
}

select {
  border: 2px solid;
}

p{
  font-weight: lighter;
}
b {
  font-weight: bold;
}

a{
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}
a:hover {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body{
}

html {
  font-size: 16px;
}

ul {
  list-style-type: disc;
  padding-left: 1.5rem;
}

li {
  font-size:1rem;
  font-weight: 500;
}
/* width */
.scroller::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey; 
  border-left-color: #e5e7eb;
  border-left-style:solid;
  border-left-width: 1px;
}
 
/* Handle */
.scroller::-webkit-scrollbar-thumb {
  /* background: red;  */
  background: #949698;
}

/* Handle on hover */
.scroller::-webkit-scrollbar-thumb:hover {
}

@keyframes rotate {
  0% {
    opacity: 0;
  }
  
  20%, 80% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
  }
}

.rotatingText {
  opacity: 0;
  position: absolute;
}

.rotatingText:nth-of-type(1) {
  animation-name: rotate;
  animation-duration: 1.5s;
  animation-delay: 0.5s;
}

.rotatingText:nth-of-type(2) {
  animation-name: rotate;
  animation-duration: 1.5s;
  animation-delay: 1.75s;
}

.rotatingText:nth-of-type(3) {
  animation-name: rotate-last;
  animation-duration: 1.5s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}

.rotateFarm {
  opacity: 0;
}

.rotateFarm:nth-of-type(1) {
  animation-name: farmRotate;
  animation-duration: 4.5s;
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
}

.rotateFarm:nth-of-type(2) {
  animation-name: farmRotate;
  animation-duration: 4.5s;
  animation-delay: 1.75s;
  animation-iteration-count: infinite;
}

.rotateFarm:nth-of-type(3) {
  animation-name: farmRotate;
  animation-duration: 4.5s;
  animation-delay: 3s;
  animation-iteration-count: infinite;
}

@keyframes farmRotate {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }
  
  10%, 50% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  
  60%, 100% {
    opacity: 0;
    transform: translate3d(0, -25px, 0);
  }
}

@keyframes rotate {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }
  
  20%, 80% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  
  100% {
    opacity: 0;
    transform: translate3d(0, -25px, 0);
  }
}

@keyframes rotate-last {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }
  
	50%, 100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@media only screen and (max-width: 500px) {
  h1.jumbo {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3rem;
  }
}
